.styled_table {
    border-collapse: collapse;
    margin: 25px auto;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled_table thead tr {
    background-color: var(--color-6);
    color: #ffffff;
    text-align: left;
}

.styled_table th,
.styled_table td {
    padding: 12px 15px;
}

.styled_table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled_table tbody tr:nth-of-type(even) {
    background-color: var(--color-6);
}

.styled_table tbody tr:last-of-type {
    border-bottom: 2px solid var(--color-6);
}

.styled_table tbody tr.active-row {
    font-weight: bold;
    color: var(--color-4);
}
.styled_table img {
    width: 100px;
    height: 100px;
}
.styled_table button {
    background-color: burlywood;
    border: none;
    cursor: pointer;
}